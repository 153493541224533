var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "choose-net" }, [
    _c(
      "div",
      {
        staticClass: "login-select-net",
        on: {
          click: function ($event) {
            _vm.isShowList = !_vm.isShowList
          },
        },
      },
      [
        _c("div", { staticClass: "mr-2" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.nets.find((item) => item.value === _vm.$store.state.chain)
                  .name
              ) +
              "\n    "
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowList,
            expression: "isShowList",
          },
        ],
        staticClass: "net-select-box",
      },
      _vm._l(_vm.nets, function (net) {
        return _c(
          "div",
          {
            key: net.value,
            staticClass: "net-select-box-item",
            on: {
              click: function ($event) {
                return _vm.changeChain(net.value)
              },
            },
          },
          [
            _c("div", {}, [_vm._v(_vm._s(net.name))]),
            _c("div", {
              staticClass: "net-select-box-item-dot",
              class:
                _vm.$store.state.chain === net.value
                  ? "green-dot"
                  : "white-dot",
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }