<template>
  <div class="choose-net">
    <div class="login-select-net" @click="isShowList = !isShowList">
      <!-- <div class="mr-2">1</div> -->
      <div class="mr-2">
        {{ nets.find((item) => item.value === $store.state.chain).name }}
      </div>
      <!-- <div class="">1</div> -->
    </div>
    <!-- {{ nets }} -->
    <div class="net-select-box" v-show="isShowList">
      <div
        class="net-select-box-item"
        @click="changeChain(net.value)"
        v-for="net in nets"
        :key="net.value"
      >
        <div class="">{{ net.name }}</div>
        <div
          class="net-select-box-item-dot"
          :class="$store.state.chain === net.value ? 'green-dot' : 'white-dot'"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nets: [{ name: "MVC", value: "MVC" }],
      isShowList: false,
    };
  },
  methods: {
    changeChain(value) {
      if (this.$store.state.chain !== value) {
        this.$store.commit("changeChain", value);
      }
      this.isShowList = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.choose-net {
  position: relative;
}
.login-select-net {
  display: flex;
  font-size: 14px;
  background-color: #edeff2;
  border-radius: 99999px;
  padding: 0 8px;
  padding-left: 12px;
  height: 30px;
  line-height: 30px;
  color: #303133;
  cursor: pointer;

  .mr-2 {
    margin-right: 8px;
  }
}

.net-select-box {
  position: absolute;
  right: 0px;
  top: 0;
  color: #303133;
  font-size: 14px;
  padding: 20px;
  border: 1px solid #edeff2;
  border-radius: 12px;
  background-color: white;
  z-index: 10;
  margin-top: 32px;

  .net-select-box-item {
    width: 121px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2px;
    padding-bottom: 2px;
    cursor: pointer;

    .net-select-box-item-dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }

    .green-dot {
      background-color: #3fcd26;
    }
    .white-dot {
      background-color: #fff;
    }
  }
}
</style>
