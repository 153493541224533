<template>
  <div
    class="login_box"
    @click="selectShow = false"
    v-loading="$store.state.metaShow"
    :element-loading-text="$store.state.loginText"
  >
    <el-row type="flex" class="login">
      <el-col :xs="24" :sm="12" class="login_left">
        <h2>VisionMoney</h2>
      </el-col>
      <el-col :xs="24" :sm="12" class="login_right">
        <div class="login_content">
          <div class="login-header">
            <h2 class="login-title">
              {{ loginType === "phone" ? $t("phoneLogin") : $t("emailLogin") }}
            </h2>

            <!-- 选择网络 -->
            <chosse-net />
          </div>

          <div class="countries" v-if="loginType === 'phone'">
            <vue-country-intl
              schema="popover"
              @onChange="handleCountryChange"
              search-input-placeholder=""
            >
              <div class="phoneCode" slot="reference">
                <div>
                  <!-- 选择国家 -->
                  {{ $t("selectCountry") }}
                  : +{{ phoneCode.dialCode }}
                  <span :class="'iti-flag ' + phoneCode.code"> </span>
                </div>
                <i class="iconfont icon-xia"> </i></div
            ></vue-country-intl>
          </div>
          <div
            class="input_box"
            :class="{ borderR: phoneShow === 3, borderY: phoneShow === 2 }"
          >
            <input
              type="text"
              :placeholder="$t('inputPhone')"
              v-if="loginType === 'phone'"
              name="phone"
              autocomplete="off"
              v-model="phone"
              readonly
              onfocus="this.removeAttribute('readonly');"
              @keyup.enter="debounceLogin()"
              @blur="getBlur(loginType)"
              :class="{ red: phoneShow === 3 }"
              @focus="getfocus('phoneShow')"
            />
            <input
              v-else
              type="text"
              :placeholder="$t('inputEmail')"
              name="email"
              autocomplete="off"
              v-model="phone"
              readonly
              onfocus="this.removeAttribute('readonly');"
              @keyup.enter="debounceLogin()"
              @blur="getBlur(loginType)"
              :class="{ red: phoneShow === 3 }"
              @focus="getfocus('phoneShow')"
            />
          </div>
          <!-- <div class="phoneTips" v-if="phoneTips" style="color: #fd4426">
            {{ $t("phoneTips") }}  @input="validataPhone"
          </div>
          <div class="emailTips" v-if="emailTips" style="color: #157bee">
            {{ $t("emailTips") }}
          </div> -->
          <div
            class="input_box"
            :class="{
              borderR: passWordShow === 3,
              borderY: passWordShow === 2,
            }"
          >
            <input
              :type="passWordType ? 'text' : 'password'"
              :placeholder="$t('inputPassword')"
              v-model="passWord"
              readonly
              onfocus="this.removeAttribute('readonly');"
              @blur="getBlur('passWord')"
              @keyup.enter="debounceLogin()"
              :class="{ red: passWordShow === 3 }"
              @focus="getfocus('passWordShow')"
            />
            <img
              v-if="!passWordType && passWord"
              src="../assets/images/list_icon_eye_1@2x.png"
              alt=""
              @click="passWordType = !passWordType"
            />
            <img
              v-if="passWordType && passWord"
              src="../assets/images/list_icon_eye_2@2x.png"
              alt=""
              @click="passWordType = !passWordType"
            />
          </div>
          <div
            class="input_box"
            :class="{
              borderR: yzmShow === 3 && YZMBtn,
              borderY: yzmShow === 2,
            }"
          >
            <input
              type="text"
              maxlength="6"
              :placeholder="$t('inputCode')"
              @keyup.enter="debounceLogin()"
              class="yzm"
              v-model="yzm"
              @blur="getBlur('yzmShow')"
              :class="{ red: yzmShow === 3 && YZMBtn }"
              @focus="getfocus('yzmShow')"
            />
            <button
              :class="yzmBtnShow ? 'yzmShow' : ''"
              @click="getyzm()"
              :disabled="YZMBtn"
            >
              {{ !YZMBtn ? $t("codeTips") : yzmText }}
            </button>
          </div>
          <div class="type_button">
            <span @click="setLoginType()" class="blue">
              {{ loginType === "phone" ? $t("emailLogin") : $t("phoneLogin") }}
            </span>
            <!-- <span @click="gotoUrl('forgetPassword')">
              {{ $t("forgetPasswordLabel") }}
            </span> -->
          </div>
          <!-- <el-checkbox v-model="$store.state.loginShow" class="auto_login">
            自动登录
            {{$t('autoLogin')}}
          </el-checkbox> -->
          <button
            class="login_button"
            :class="{ login_buttonS: phone && passWord && yzm }"
            @click="debounceLogin()"
            v-loading="loginLoading"
          >
            <!-- 登录 -->
            {{ $t("login") }}
          </button>
          <div class="type_button signUp">
            <span @click="gotoUrl('sign')">
              <!-- 账号注册 -->
              {{ $t("notAccount")
              }}<span class="blue">{{ $t("sginUp2") }}</span>
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="MetaID Creation"
      class="user_home"
      :visible.sync="metaTipt"
    >
      <span>{{ $t("metaTxt") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="outLogin()">{{ $t("quit") }}</el-button>
        <el-button type="primary" @click="createMeta()">
          <!-- 升级 -->
          {{ $t("upgrade") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import countries from "@/common/js/countryList";
import {
  loginVerification,
  logincheck,
  getEnCryptedMnemonic,
  getInviteCode,
} from "@/api/api";
import utils from "../common/js/util";
import { isVerifiedPhone, isVerifiedEmail } from "@/common/js/verification";
import chosseNet from "../components/chosseNet.vue";
// import axios from 'axios'
// import CryptoJS from 'crypto-js'
export default {
  components: {
    "chosse-net": chosseNet,
  },
  data() {
    return {
      metaTipt: false,
      countries: countries,
      MetaLock: false,
      netSwitchShown: false,
      selectedNet: "MVC",
      phoneCode: {
        code: "us",
        name: "United States",
        dialCode: 1,
        phoneFormat: "131 2345 6789",
      },
      loginType: "email",
      codeimg: "cn",
      selectShow: false,
      phone: "",
      phoneShow: false,
      passWordShow: false,
      passWord: "",
      yzm: "",
      yzmShow: false,
      yzmText: this.$t("codeTips"),
      yzmBtnShow: false,
      loginLoading: false,
      autoLogin: false,
      timeNub: 60,
      timeC: "",
      // 登录信息
      token: "",
      pk2: "",
      did: "",
      wallet: "",
      userData: "",
      YZMBtn: false,
      passWordType: false,
      // phoneTips: false,
      // emailTips: false,
    };
  },
  computed: {
    tip1() {
      return this.$t("codeTips");
    },
    isChinesePhone() {
      if (this.phoneCode.dialCode == 86) {
        return this.phone;
      } else {
        return this.phoneCode.dialCode + this.phone;
      }
    },
  },
  watch: {
    // loginType: {
    //   handler() {
    //     this.phoneTips = false;
    //     this.emailTips = false;
    //   },
    // },
    phone() {
      if (this.phone) {
        this.yzmBtnShow = true;
      } else {
        this.yzmBtnShow = false;
      }
    },
    "$store.state.metaShow": function () {
      // console.log(this.$store)
      let userMeta = this.$store.state.userMeta;
      console.log(userMeta);
      if (!this.$store.state.metaShow) {
        // console.log()
        if (userMeta.showId && userMeta.infoTxId && userMeta.name) {
          if (this.$route.query.from) {
            window.location.href = this.$route.query.from + "?type=registered";
          } else {
            setTimeout(() => {
              this.gotoUrl("index");
            }, 1000);
          }
        } else {
          return this.$message.error(this.$t("metaTxt"));
        }
      }
    },
  },
  methods: {
    // validataPhone(e) {
    //   const phoneReg = /^[0-9]*$/;
    //   const emailReg =
    //     /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    //   let {
    //     target: { value },
    //   } = e;
    //   if (this.loginType === "phone") {
    //     if (phoneReg.test(value)) {
    //       this.phoneTips = false;
    //     } else {
    //       this.phoneTips = true;
    //     }
    //   } else {
    //     if (emailReg.test(value) || value === "") {
    //       this.emailTips = false;
    //     } else {
    //       this.emailTips = true;
    //     }
    //   }
    // },
    // 重新生成 MEtaID
    outLogin() {
      console.log("删除密码");
      this.$store.state.userData = "";
      localStorage.clear();
      sessionStorage.clear();
      location.reload();
      // this.gotoUrl('login')
    },
    createMeta() {
      const metaIdLoading = this.$loading({
        lock: true,
        text: "Initializing MetaID...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // this.$utils.getShowIDUserData()
      this.$store.state.metanet
        .initMetaId({
          isMetasv: true,
          onSuccess: (res) => {
            console.log(res);
            this.$store.state.userData.metaId = res.metaId;
            this.$store.state.userMeta = res;
            this.$store.state.infoTxId = res.infoTxId;
            localStorage.setItem(
              "localuserData",
              JSON.stringify(this.$store.state.userData)
            );
          },
          onError: (error) => {
            console.error(error);
            if (error.data && error.data.message) {
              this.$message.error(error.data.message);
            }
          },
        })
        .then((res) => {
          this.gotoUrl("index");
        })
        .catch((error) => {
          metaIdLoading.close();
          console.log(error);
          this.$message.error(this.$t("Error") + error);
          console.log("删除密码");
          localStorage.clear();
          sessionStorage.clear();
        });
    },
    GetPhone(item) {
      this.phoneCode = item;
    },
    getfocus(type, e) {
      this[type] = 2;
    },
    getBlur(type) {
      if (!this[type] && type !== "") {
        this[type + "Show"] = 3;
      } else {
        this[type + "Show"] = 1;
      }
      if (type === "phone" && !isVerifiedPhone(this.phone)) {
        this.$message.error(this.$t("phoneErr"));
      } else if (type === "email" && !isVerifiedEmail(this.phone)) {
        this.$message.error(this.$t("emailErr"));
      }
    },
    setLoginType(isCheck = true) {
      if (isCheck) {
        this.loginType === "phone"
          ? (this.loginType = "email")
          : (this.loginType = "phone");
      }
      this.phone = "";
      this.passWord = "";
      this.yzm = "";
      this.$ShowMoney.noneMnemonic.setLoginType(this.loginType);
      this.$store.dispatch("loginType", this.loginType);
      this.clearYZMText();
      // console.log(this.loginType)
    },
    setPhoneNub() {
      let phone = this.phone;
      if (this.phoneCode.dialCode != 86 && this.loginType === "phone") {
        phone = this.phoneCode.dialCode + this.phone;
      }
      return phone;
    },
    getyzm() {
      // debugger;
      if (!this.phone) {
        return this.$message.error(
          this.loginType === "phone"
            ? this.$t("phoneNull")
            : this.$t("emailNull")
        );
      }
      let re =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
      if (this.loginType === "email" && !re.test(this.phone)) {
        return this.$message.error(this.$t("emaiErr"));
      }
      let phone =
        (this.phoneCode.dialCode != 86 && this.loginType === "phone"
          ? "+"
          : "") + this.setPhoneNub();
      let params = {
        userType: this.loginType,
        isEnglish: this.$i18n.locale !== "zh",
      };
      params.userType === "phone"
        ? (params.phone = phone)
        : (params.email = phone);
      this.YZMBtn = true;
      this.setYZMText();
      loginVerification(params)
        .then(() => {
          this.$message({
            message: this.$t("codeSuccess"),
            type: "success",
          });
        })
        .catch((err) => {
          this.clearYZMText();
          return this.$message.error(this.$t("Error") + err.message);
        });
    },
    setYZMText() {
      this.yzmText = this.timeNub + "s";
      if (this.timeNub > 0) {
        this.timeC = setTimeout(() => {
          --this.timeNub;
          this.yzmText = this.timeNub + "s";
          this.setYZMText();
        }, 1000);
      } else {
        this.YZMBtn = false;
        this.timeNub = 60;
        this.yzmText = this.$t("codeTips");
      }
    },
    handleCountryChange(item) {
      console.log(item);
      this.phoneCode.code = item.iso2;
      this.phoneCode.dialCode = item.dialCode;
    },
    clearYZMText() {
      if (this.timeC) {
        clearTimeout(this.timeC);
        this.YZMBtn = false;
        this.timeNub = 60;
        this.loginLoading = false;
        this.yzmText = this.$t("codeTips");
      }
    },
    debounceLogin() {
      this.debounce(this.subLogin, 1000, true);
    },
    subLogin() {
      try {
        if (this.loginLoading) {
          return this.$message.error(this.$t("discussWarning"));
        }
        if (this.phone && this.passWord && this.yzm) {
          this.loginLoading = true;
          let phone = this.setPhoneNub();
          let params = {
            type: 1,
            code: this.yzm,
            userType: this.loginType,
            password: utils.encryptPassword(this.passWord),
          };
          params.userType === "phone"
            ? (params.phone = phone)
            : (params.email = phone);
          this.$store.dispatch("loginType", params.userType);
          this.$store.dispatch(
            params.userType == "phone" ? "loginPhone" : "loginEmail",
            phone
          );
          logincheck(params)
            .then(async (res) => {
              console.log(res, "用户数据");

              // 拦截path为236的旧用户
              // if (res.path === 236) {
              //   this.$message.error(this.$t("oldUser"));
              //   this.loginLoading = false;
              //   return;
              // }
              if (res.register) {
                localStorage.setItem("loginType", res.register);
              }
              if (res.code === 601) {
                let token = "";
                let pk2 = "";
                if (res.token) {
                  token = res.token;
                  pk2 = res.pk2;
                  res["token"] = token;
                  res["loginType"] = this.loginType;
                  this.$store.dispatch("userData", res);
                } else {
                  this.clearYZMText();
                  this.loginLoading = false;
                  this.$message.warning("logincheck err");
                  return false;
                }
                const register = res["register"];
                const _m = this.$utils.createMnemonicByPk2(
                  res[register],
                  this.passWord,
                  pk2,
                  res.tag
                );
                const zeroAddress = this.$utils.creadZeroAddress(_m, res.tag);

                console.log("zeroAddress", zeroAddress);
                this.$store.commit("setAddress0", zeroAddress);
                const checkResult = await this.$utils
                  .checkPasswordAddress(
                    zeroAddress,
                    register,
                    res[register],
                    this
                  )
                  .catch((err) => {
                    return err;
                  });
                if (checkResult) {
                  this.$router.replace({
                    name: "setPassword",
                    params: {
                      token: token,
                      pk2: pk2,
                      password: this.passWord,
                    },
                  });
                } else {
                  this.loginLoading = false;
                  this.clearYZMText();
                  return this.$message.error(this.$t("passWordErr"));
                }
                return false;
              }
              this.did = res.did;
              this.pk2 = res.pk2;
              this.token = res.token;
              this.userData = res;
              // this.userData.passWord = this.passWord
              // this.userData.phone = phone
              this.userData.headers = this.$ShowMoney.noneMnemonic.getHeader();
              // console.log(this.userData.headers)
              this.userData.register = res.register;
              this.userData.path = res.path;
              let re =
                /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
              re.test(phone)
                ? (this.userData.email = phone)
                : (this.userData.phone = phone);
              // console.log(this.$ShowMoney.noneMnemonic.getHeader().accessKey)
              // 进行登录
              // if (this.pk2) {
              this.login().catch((err) => {
                this.loginLoading = false;
                console.log(2222222, err);
                return this.$message.error(this.$t("Error") + err.message);
              });
              // }
              // }
            })
            .catch((err) => {
              this.loginLoading = false;
              this.yzmShow = 3;
              return this.$message.error(
                err?.message == "Incorect code"
                  ? this.$t("yzmError")
                  : err.message
              );
            });
          // 提示未填写的信息
        } else {
          let from = ["phone", "passWord", "yzm"];
          for (let i = 0; i < from.length; i++) {
            if (!this[from[i]]) {
              this[from[i] + "Show"] = 3;
            }
          }
          return this.$message.error(this.$t("submitTips"));
        }
      } catch (error) {
        console.log(error);
        this.clearYZMText();
      }
    },
    alert(item) {
      window.alert(item);
    },
    async login() {
      // let phone = this.setPhoneNub()
      // console.log(this.userData.register)
      const that = this;
      let de_mnemonic = "";
      this.userData.loginType = this.loginType;
      that.$store.state.userData = this.userData;
      that.$cookie.set(
        "userData",
        true,
        60 * 60 * 24 * 30,
        null,
        null,
        true,
        "None"
      );
      let accessKey = this.$utils.encrypt(
        this.passWord,
        this.pk2.slice(0, 32),
        this.pk2.slice(32, 64)
      );
      // console.log(this.$utils.decrypt(accessKey, this.pk2.slice(0, 32)))
      // let accessKey = escape(JSON.stringify(key))
      // if (this.$store.state.loginShow) {
      //   // console.log('自动登录')
      //   localStorage.setItem("accessKey", accessKey);
      // } else {
      //   // console.log('非自动登录')
      //   sessionStorage.setItem("accessKey", accessKey);
      // }
      localStorage.setItem("accessKey", accessKey);
      localStorage.setItem("loginShow", this.$store.state.loginShow);

      // let re = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
      // if (re.test(this.userData.register)) {
      //   // console.log('我进来了')
      //   this.$ShowMoney.noneMnemonic.setLoginType("email");
      // } else {
      //   this.$ShowMoney.noneMnemonic.setLoginType("phone");
      // }
      // let key = this.$ShowMoney.noneMnemonic.createMasterHdPrivateKey(
      //   this.userData.register,
      //   this.passWord,
      //   this.pk2,
      //   this.userData.tag
      // );
      // let that = this;
      // this.userData.loginType = this.loginType;
      // that.$store.state.userData = this.userData;
      // that.$cookie.set(
      //   "userData",
      //   true,
      //   60 * 60 * 24 * 30,
      //   null,
      //   null,
      //   true,
      //   "None"
      // );
      // let accessKey = this.$utils.encrypt(
      //   this.passWord,
      //   this.pk2.slice(0, 32),
      //   this.pk2.slice(32, 64)
      // );
      // // console.log(this.$utils.decrypt(accessKey, this.pk2.slice(0, 32)))
      // // let accessKey = escape(JSON.stringify(key))
      // if (this.$store.state.loginShow) {
      //   // console.log('自动登录')
      //   localStorage.setItem("accessKey", accessKey);
      // } else {
      //   // console.log('非自动登录')
      //   sessionStorage.setItem("accessKey", accessKey);
      // }
      // localStorage.setItem("loginShow", this.$store.state.loginShow);
      //  de_mnemonic = this.$ShowMoney.noneMnemonic.decodeMnemonic(
      //   key.mnemonic,
      //   key.ppk
      // );

      const result = await getEnCryptedMnemonic({
        userType: this.loginType,
        phone: this.loginType == "phone" ? this.isChinesePhone : "",
        email: this.loginType == "email" ? this.phone : "",
        token: this.token,
      });
      de_mnemonic = this.$utils.aesDescryptMem(
        result.enCryptedMnemonic,
        this.passWord
      );
      if (!de_mnemonic) {
        this.clearYZMText();
        return this.$message.error(this.$t("passWordErr"));
      }
      console.log("解码后的助记词：", de_mnemonic);
      const encrypt_mnemonic = this.$utils.aesEncryptMem(
        de_mnemonic,
        this.passWord
      );
      setTimeout(() => {
        this.$utils
          .newWallet(
            { ppk: "", mnemonic: de_mnemonic },
            "login",
            "",
            encrypt_mnemonic
          )
          .then(async (r) => {
            // TODO:bug
            this.$store.state.metanet = r;
            this.loginLoading = false;
            // this.$forceUpdate()
            // console.log(that.$store.state.userData)
            const inviteCodeRes = await getInviteCode();
            let userData = that.userData;
            if (inviteCodeRes.promotionCode) {
              userData = {
                ...userData,
                inviteCode: inviteCodeRes.promotionCode,
              };
            }
            console.log("uuuull", userData);
            localStorage.setItem("loginType", that.loginType);
            localStorage.setItem("localuserData", JSON.stringify(userData));
            // setTimeout(() => {
            //   // console.log('60S 了')
            //   this.$store.state.metaShow = false
            //   // let userMeta = this.$store.state.userMeta
            // }, 60000)
            console.log("zxzxssasas", this.$store.state.metanet);
            const tolink = localStorage.getItem("tolink");
            console.log("tolink", tolink);
            const metanetSdk = this.$store.state?.metanet;
            if (metanetSdk) {
              if (metanetSdk.isGotMetaIdInfo && !metanetSdk.isMetaIdCompleted) {
                console.log("需要升级");
                // that.metaTipt = true;
                that.createMeta();
              } else {
                if (tolink) {
                  that.gotoUrl("link");
                } else {
                  that.gotoUrl("index");
                }
              }
            } else {
              return this.$message.error(this.$t("walletError"));
            }
          })
          .catch((err) => {
            // return;
            this.loginLoading = false;
            if (err === "incorect address") {
              return this.$message.error(this.$t("passWordErr"));
            }
            if (err === "wrong password") {
              return this.$message.error(this.$t("passWordErr"));
            }
            return this.$message.error(this.$t("Error") + err);
          });
      }, 20);
    },
  },
  destroyed() {
    this.clearYZMText();
    // clearInterval(this.timeC);
  },
  created() {
    // this.yzmText = this.tip1
    const invitationCode = this.$route.query.refCode;
    if (invitationCode) {
      localStorage.setItem("referCode", invitationCode);
    }
  },
  mounted() {
    this.setLoginType(false);
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/intl.css";

.login_box {
  background: url("../assets/images/home_bg.png") no-repeat;
  width: 100vw;
  background-position: 0;
  background-size: cover;
  min-height: calc(100vh - 68px);
}
.login {
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 7vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.login_left {
  padding-left: 20px;
  h2 {
    margin-top: 22px;
    font-size: 45px;
    color: #fff;
  }
  h4 {
    font-size: 16px;
    margin-bottom: 25px;
    color: #bfc2cc;
    font-weight: normal;
  }
  p {
    font-size: 14px;
    color: #bfc2cc;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    i {
      width: 8px;
      height: 8px;
      background: rgba(234, 179, 0, 1);
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .app_downloads {
    display: flex;
    margin-top: 45px;
    a {
      width: 195px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.08);
      border: 1px solid rgba(255, 255, 255, 0.8);
      border-radius: 30px;
      margin-right: 22px;
      i {
        font-size: 22px;
        margin-right: 10px;
      }
    }
  }
}
::v-deep .vue-country-intl .country-intl-input-wrap {
  border: unset;
}
::v-deep .vue-country-intl .country-intl-label {
  padding-left: 0;
  padding: 7px 16px;
  font-size: 14px;
}
.login_right {
  padding: 20px;
}
.login_content {
  max-width: 390px;
  background: #fff;
  margin: 0 auto;
  border-radius: 17px;
  padding: 50px;

  .login-header {
    display: flex;

    .login-title {
      flex: 1;
    }
  }
  h2 {
    color: #303133;
    font-size: 26px;
    margin-bottom: 22px;
    font-family: PingFang SC;
    position: relative;

    .net-select-box {
      position: absolute;
      right: 0px;
      bottom: -114px;
      color: #303133;
      font-size: 14px;
      padding: 20px;
      border: 1px solid #edeff2;
      border-radius: 12px;
      background-color: white;
      z-index: 10;

      .net-select-box-item {
        width: 121px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 2px;
        padding-bottom: 2px;
        cursor: pointer;

        .net-select-box-item-dot {
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }

        .green-dot {
          background-color: #3fcd26;
        }
        .white-dot {
          background-color: #fff;
        }
      }
    }
  }
  .login-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .countries {
    height: 40px;
    font-size: 15px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #bfc2cc;
    .phoneCode {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .select_phone {
      position: absolute;
      height: 300px;
      left: 0;
      top: 95%;
      z-index: 1;
      background: #fff;
      width: 100%;
      overflow: auto;
      padding: 0 20px;
      border-radius: 5px;
      border: 1px solid #bfc2cc;
    }
    .select_phone::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 10px;
    }
    .select_phone::-webkit-scrollbar-track {
      box-shadow: none;
      /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.1);
    }
    .select_phone::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
      background-color: #aaa;
    }
    .item_phone {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 40px;
      line-height: 40px;
      .item_name {
        width: 60%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
.input_box {
  height: 60px;
  font-size: 15px;
  line-height: 60px;
  width: 100%;
  border-bottom: 1px solid #bfc2cc;
  transition: 0.3s all ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 25px;
    height: 25px;
  }
  .red {
    &::-webkit-input-placeholder {
      color: #f56c6c;
    }
    &:-moz-placeholder {
      color: #f56c6c;
    }
    &::-moz-placeholder {
      color: #f56c6c;
    }
    &:-ms-input-placeholder {
      color: #f56c6c;
    }
  }
  input {
    height: 59px;
    font-size: 15px;
    color: #303133;
    border: none;
    display: block;
    outline: none;
    width: 100%;
  }
  .yzm {
    width: unse t;
  }
  button {
    font-size: 12px;
    color: #f9f9f9;
    width: 120px;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    background: rgba(191, 194, 204, 1);
    border-radius: 5px;
  }
  .yzmShow {
    background: #eab300;
  }
}
.borderY {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #eab300;
}
.borderR {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #f56c6c;
}
.signUp {
  justify-content: center !important;
}
.type_button {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  span {
    cursor: pointer;
    color: #606266;
    font-size: 15px;
  }
  .blue {
    color: #157bee;
  }
}
.auto_login {
  margin-top: 20px;
  font-size: 16px;
}
.login_button {
  cursor: pointer;
  max-width: 270px;
  width: 100%;
  height: 60px;
  background: rgba(191, 194, 204, 1);
  border-radius: 12px;
  color: #fff;
  font-size: 24px;
  line-height: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.login_buttonS {
  background: #eab300;
}
::v-deep .el-loading-spinner .path {
  stroke: #eab300;
}
@media only screen and (max-width: 768px) {
  .login_right {
    padding-bottom: 60px;
  }
  .login_left .app_downloads a {
    font-size: 12px;
  }
}
</style>
